import { AxiosError } from 'axios';

import axiosInstance from './axiosInstance';
import { CustomBaseQuery } from './typings';

export type AxiosBaseQueryReturnValue<TResult = unknown> = ReturnType<CustomBaseQuery<TResult>>;

export const axiosBaseQuery: CustomBaseQuery = async ({ url, method = 'GET', data, params, headers }) => {
  try {
    const result = await axiosInstance({ url, method, data, params, headers });

    return { data: result.data };
  } catch (axiosError) {
    const err = axiosError as AxiosError;

    if (err.response?.status === 431) {
      // Clear the specific "redirect" cookie
      document.cookie = `redirect=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;domain=${location.hostname};`;

      setTimeout(() => {
        window.location.reload();
      }, 5000);
    }

    return {
      error: {
        status: err.response?.status,
        data: err.response?.data || err.message,
      },
    };
  }
};

